body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/*Ring UI tweaks:*/

div[data-test="ring-pager"] {
  text-align: center;
}

header {
  background-color: black !important;
}

div[data-test="alert"] {
  background-color: #028eff;
  font-weight: 700;
}

button[aria-label="close alert"] svg[class*="glyph_rui"] {
  color: white !important;
}

div[data-test="ring-island-header"] {
  padding: 8px 32px !important;
}

.wide-error-message {
  max-width: 800px !important;
}

div[data-test="ring-island ring-dialog"] {
  padding: 10px;
}

.custom-select-option span[data-test="ring-list-item-label"] {
  display: none;
}

.custom-select-option span[data-test="ring-list-item-description"] {
  flex: 1 0;
  text-align: left;
  font-size: 1em;
}

label[data-test="ring-checkbox"] > span:nth-child(2) {
  width: 14px;
  height: 14px;
  overflow: hidden;
}

/* for some reason RingUI adds a white background to disabled inputs: */
input[class^="input_rui_"]:disabled {
  background-color: #0000 !important;
}
